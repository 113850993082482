import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiOutlineDownload, HiOutlineThumbUp, HiOutlineThumbDown } from 'react-icons/hi';
import ReactMarkdown from 'react-markdown';

const ChatHistory = () => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [loadingConversations, setLoadingConversations] = useState(true);

    const fetchChatHistory = async () => {
        setLoadingConversations(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/chat-history`);

            console.log("Chat History API Response:", response.data);

            if (!Array.isArray(response.data)) {
                console.error("Chat history API did not return an array:", response.data);
                return;
            }

            const chatData = response.data[0];

            if (!chatData.conversations || !Array.isArray(chatData.conversations)) {
                console.error("Invalid conversation format:", chatData);
                return;
            }

            const conversationsWithReactions = chatData.conversations.map((conv) => {
                if (!conv.messages || !Array.isArray(conv.messages)) {
                    console.error("Invalid messages format in conversation:", conv);
                    return { ...conv, messages: [], totalThumbsUp: 0, totalThumbsDown: 0 };
                }

                // ✅ Ensure reactions are correctly calculated
                let totalThumbsUp = 0;
                let totalThumbsDown = 0;

                conv.messages.forEach((msg) => {
                    totalThumbsUp += msg.thumbsUp ? msg.thumbsUp : 0;
                    totalThumbsDown += msg.thumbsDown ? msg.thumbsDown : 0;
                });

                return { ...conv, totalThumbsUp, totalThumbsDown };
            });

            setConversations(conversationsWithReactions);
        } catch (error) {
            console.error("Error fetching chat history:", error);
        } finally {
            setLoadingConversations(false);
        }
    };

    useEffect(() => {
        fetchChatHistory();
    }, []);

    const filteredConversations = conversations.filter(conv => {
        const startedAt = new Date(conv.startedAt);
        return (!startDate || startedAt >= startDate) && (!endDate || startedAt <= new Date(endDate.setHours(23, 59, 59, 999)));
    }).sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt)); // Sorting newest on top

    // Generate CSV data for all filtered conversations
    const generateCSV = () => {
        if (filteredConversations.length === 0) return;

        const data = [];
        data.push(['Date', 'Sender', 'Message']); // CSV Header

        filteredConversations.forEach(conv => {
            // Add a header for the conversation with the start date
            const conversationDate = new Date(conv.startedAt).toLocaleString();
            data.push([`Conversation started on: ${conversationDate}`, '', '']); // Add conversation header

            conv.messages.forEach(msg => {
                const date = new Date(msg.timestamp || conv.startedAt).toLocaleString();
                const sender = msg.sender === 'user' ? 'User' : 'Bot';
                const content = msg.content.replace(/"/g, '""'); // Escape double quotes
                data.push([date, sender, `"${content}"`]); // Enclose message in quotes
            });

            data.push(['', '', '']); // Add a blank row after each conversation for spacing
        });

        const csvContent = data.map(e => e.join(",")).join("\n");
        return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    };

    const downloadCSV = () => {
        const blob = generateCSV();
        if (!blob) {
            console.error("No data to download");
            return;
        }

        const url = URL.createObjectURL(blob);
        const filename = `chat_history_${new Date().toISOString().split('T')[0]}.csv`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex flex-col md:flex-row h-full gap-2">
            <div className="w-full md:w-[600px] h-full bg-gray-100 flex flex-col p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                <h1 className="text-2xl font-bold mb-4">Chat History</h1>
                <div className="flex items-center mb-4 justify-between">
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => setDateRange(update)}
                        isClearable={true}
                        dateFormat="yyyy-MM-dd"
                        className="p-2 border border-gray-300 rounded w-[200px] h-[30px] text-sm"
                        placeholderText="Select a date range"
                    />
                    <button onClick={downloadCSV}>
                        <HiOutlineDownload className="text-xl" />
                    </button>
                </div>
                {/* <div className="flex-1 space-y-2 overflow-y-auto">
                    {filteredConversations.map((conv, index) => (
                        <div key={index} onClick={() => setSelectedConversation(conv)}
                            className="bg-white p-3 rounded-lg cursor-pointer shadow hover:shadow-md">
                            <div className="flex justify-between items-center mb-1">
                                <span className="text-black font-semibold text-md">{new Date(conv.startedAt).toLocaleString()}</span>
                                <div className="flex space-x-2">
                                    <span className="flex items-center text-green-500">
                                        <HiOutlineThumbUp className="mr-1" /> {conv.totalThumbsUp}
                                    </span>
                                    <span className="flex items-center text-red-400">
                                        <HiOutlineThumbDown className="mr-1" /> {conv.totalThumbsDown}
                                    </span>
                                </div>
                            </div>
                            <p className="text-sm text-gray-600">
                                {conv.messages.length > 0
                                    ? (conv.messages[conv.messages.length - 1].content
                                        ? conv.messages[conv.messages.length - 1].content.split(" ").slice(0, 20).join(" ") +
                                        (conv.messages[conv.messages.length - 1].content.split(" ").length > 20 ? "..." : "")
                                        : "No content available")
                                    : "No messages"}
                            </p>
                        </div>
                    ))}
                </div> */}
                <div className="flex-1 space-y-2 overflow-y-auto">
                    {loadingConversations ? (
                        // 🔹 Skeleton Loader While Fetching
                        [...Array(5)].map((_, index) => (
                            <div key={index} className="bg-gray-300 p-3 rounded-lg shadow animate-pulse">
                                <div className="flex justify-between items-center mb-1">
                                    <span className="h-4 w-24 bg-gray-400 rounded"></span>
                                    <div className="flex space-x-2">
                                        <span className="h-4 w-6 bg-gray-400 rounded"></span>
                                        <span className="h-4 w-6 bg-gray-400 rounded"></span>
                                    </div>
                                </div>
                                <div className="h-4 w-full bg-gray-400 rounded mb-2"></div>
                                <div className="h-4 w-3/4 bg-gray-400 rounded"></div>
                            </div>
                        ))
                    ) : (
                        // 🔹 Render Real Conversations After Loading
                        filteredConversations.map((conv, index) => (
                            <div key={index} onClick={() => setSelectedConversation(conv)}
                                className="bg-white p-3 rounded-lg cursor-pointer shadow hover:shadow-md">

                                <div className="flex justify-between items-center mb-1">
                                    <span className="text-black font-semibold text-md">
                                        {new Date(conv.startedAt).toLocaleString()}
                                    </span>
                                    <div className="flex space-x-2">
                                        {/* ✅ Ensure Reactions Always Show Correctly */}
                                        <span className="flex items-center text-green-500">
                                            <HiOutlineThumbUp className="mr-1" />
                                            {conv.totalThumbsUp !== undefined ? conv.totalThumbsUp : 0}
                                        </span>
                                        <span className="flex items-center text-red-400">
                                            <HiOutlineThumbDown className="mr-1" />
                                            {conv.totalThumbsDown !== undefined ? conv.totalThumbsDown : 0}
                                        </span>
                                    </div>
                                </div>

                                <p className="text-sm text-gray-600">
                                    {conv.messages.length > 0
                                        ? (conv.messages[conv.messages.length - 1].content
                                            ? conv.messages[conv.messages.length - 1].content.split(" ").slice(0, 20).join(" ") +
                                            (conv.messages[conv.messages.length - 1].content.split(" ").length > 20 ? "..." : "")
                                            : "No content available")
                                        : "No messages"}
                                </p>
                            </div>
                        ))
                    )}
                </div>

            </div>
            <div className="w-full md:w-[calc(100%-400px)] h-full bg-white p-6 rounded-lg overflow-hidden">
                <h2 className="text-xl font-bold mb-2">Conversation Details</h2>
                {selectedConversation ? (
                    <div className="flex flex-col h-full">
                        <div className="flex-1 overflow-y-auto mt-2 mb-6">
                            {selectedConversation.messages.map((msg, msgIndex) => (
                                <div key={msgIndex} className="m-2">
                                    <span className={`block text-xs font-bold mb-1 ${msg.sender === 'user' ? 'text-green-600' : 'text-blue-600'}`}>
                                        {msg.sender.charAt(0).toUpperCase() + msg.sender.slice(1)}
                                    </span>
                                    <div className={`p-3 rounded-lg ${msg.sender === 'user' ? 'bg-gray-100 text-black' : 'bg-blue-500 text-white'}`}>
                                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                                    </div>
                                    <div className="flex space-x-2 mt-1 text-gray-500">
                                        <span className="flex items-center text-green-500">
                                            <HiOutlineThumbUp className="mr-1 text-green-500" /> {msg.thumbsUp}
                                        </span>
                                        <span className="flex items-center text-red-400">
                                            <HiOutlineThumbDown className="mr-1" /> {msg.thumbsDown}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-600">Select a conversation to view details.</p>
                )}
            </div>
        </div>
    );
};

export default ChatHistory;
